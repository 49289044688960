<template>
  <div class="requirement--field mb-4" :class="{ 'has-error': validation.hasError('requirement') }">
    <span class="mr-2 mt-3">{{ index + 1 }}. </span>
    <div style="flex-grow: 1; padding-right: 12px">
      <multiselect
        name="product"
        id="product"
        v-model="requirement.product"
        :options="productTypes"
        @input="inputProduct"
        :searchable="true"
        :close-on-select="true"
        track-by="id"
        label="name"
        placeholder="Pilih Tipe Produk"
      />
      <multiselect
        name="renewalType"
        id="renewalType"
        v-model="requirement.renewalType"
        :options="renewalTypes"
        @input="inputRenewalType"
        :searchable="true"
        :close-on-select="true"
        track-by="id"
        label="name"
        placeholder="Pilih Tipe Renewal"
        style="margin-top: 8px"
        v-if="requirement && requirement.product && requirement.product.id === '6'"
      />
      <multiselect
        name="membership"
        id="membership"
        v-model="requirement.membership"
        :options="membershipTypes"
        @input="inputMembership"
        :searchable="true"
        :close-on-select="true"
        track-by="id"
        label="name"
        placeholder="Pilih Jenis Membership"
        style="margin-top: 8px"
      />
      <div
        v-show="requirement.product?.id == 4"
        class="form--group row mt-1"
        :class="{ 'has-error': validation.hasError('minQty') }"
      >
        <label class="col-12 col-lg-6" for="qty">Minimal Qty</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="title"
            v-model="minQty"
            type="number"
            name="min-qty"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('minQty') }}</span>
        </div>
      </div>
      <div
        v-show="requirement.product?.id == 4"
        class="form--group row mt-1"
        :class="{ 'has-error': validation.hasError('minDuration') }"
      >
        <label class="col-12 col-lg-6" for="qty">Minimal Durasi (Hari)</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="title"
            v-model="minDuration"
            type="number"
            name="min-duration"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('minDuration') }}</span>
        </div>
      </div>
      <div class="val-error" v-if="validation.hasError('requirement')">
        {{ validation.firstError('requirement') }}
      </div>
    </div>
    <v-btn color="error" @click="removeRequirement">
      <v-icon v-text="'mdi-trash-can-outline'" />
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { Validator } from 'simple-vue-validator';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'requirement-field',
  props: ['requirement', 'index', 'requirementLength'],
  components: { Multiselect },
  computed: {
    ...mapState({
      membershipTypes: (state) => state.promo.form.membershipTypes,
      productTypes: (state) => state.promo.form.productTypes,
      renewalTypes: (state) => state.promo.form.renewalTypes,
    }),
    minQty: {
      get() {
        if (this.requirement.minQty !== null && this.requirement.minQty !== undefined) {
          return this.requirement.minQty;
        } else {
          return null;
        }
      },
      set(val) {
        this.$store.commit('promo/form/INPUT_REQUIREMENT_MIN_QTY', {
          minQty: val,
          index: this.index,
        });
      },
    },
    minDuration: {
      get() {
        if (this.requirement.minDuration !== null && this.requirement.minDuration !== undefined) {
          return this.requirement.minDuration;
        } else {
          return null;
        }
      },
      set(val) {
        this.$store.commit('promo/form/INPUT_REQUIREMENT_MIN_DURATION', {
          minDuration: val,
          index: this.index,
        });
      },
    },
  },
  validators: {
    requirement(value) {
      if (this.requirement && this.requirement.product) {
        if (this.requirement.product.id === '6') {
          return Validator.value(value.renewalType).required(
            this.$i18n.t('Tipe Renewal harus dipilih'),
          );
        } else {
          // TODO This is buggy
          // return Validator.value(value.membership).required(
          //   this.$i18n.t('Jenis Membership harus dipilih'),
          // );
        }
      }
      return Validator.value(value);
    },
    minQty(value) {
      if (this.requirement && this.requirement.product) {
        if (this.requirement.product.id == 4) {
          return Validator.value(value)
            .required(this.$i18n.t('Minimal QTY harus dipilih'))
            .greaterThan(0, 'Minimal QTY harus lebih dari 0')
            .custom(() => {
              let valid = value % 10 === 0;
              if (!valid) {
                return this.$i18n.t('Minimal QTY harus kelipatan 10');
              }
            });
        } else {
          return Validator.value(value);
        }
      }
      return Validator.value(value);
    },
    minDuration(value) {
      if (this.requirement && this.requirement.product) {
        if (this.requirement.product.id == 4) {
          return Validator.value(value)
            .required(this.$i18n.t('Minimal Durasi harus dipilih'))
            .greaterThan(0, 'Minimal Durasi harus lebih dari 0')
            .lessThanOrEqualTo(360, 'Minimal Durasi harus lebih dari 360')
            .custom(() => {
              let valid = value % 30 === 0;
              if (!valid) {
                return this.$i18n.t('Minimal Durasi harus kelipatan 30');
              }
            });
        } else {
          return Validator.value(value);
        }
      }
      return Validator.value(value);
    },
  },
  methods: {
    inputMembership(input) {
      this.$store.commit('promo/form/INPUT_REQUIREMENT_MEMBERSHIP', {
        membership: input,
        index: this.index,
      });
    },
    inputProduct(input) {
      this.$store.commit('promo/form/INPUT_REQUIREMENT_PRODUCT', {
        product: input,
        index: this.index,
      });
      this.inputRenewalType(null);
    },
    inputRenewalType(input) {
      this.$store.commit('promo/form/INPUT_REQUIREMENT_RENEWAL_TYPE', {
        renewalType: input,
        index: this.index,
      });
    },
    removeRequirement() {
      this.$store.commit('promo/form/REMOVE_REQUIREMENTS', this.index);
    },
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.requirement--field {
  display: flex;
}
</style>
